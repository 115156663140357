/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const airMiniAppChangeLogStatic = [
  {
    version: '2.2.5',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>
            New Features and Improvements:
          </strong>
        </p>
        <ul>
          <li>
            Enhanced touch sensitivity and input processing to make interactions smoother, more responsive, and accurate.
          </li>
        </ul>
      </>
    ),
  },
];

export default airMiniAppChangeLogStatic;
