import React from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { BrowserRouter, Redirect } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import { loadableReady } from "@loadable/component";

import createStore from "../shared/createStore";
import Routes from "../shared/routes";
import { selectLocalizationData } from "./features/localization/localizationSlice";
import { SUPPORTED_REGIONS } from "../shared/enums";

const state = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const store = createStore(state);

const redirectRegionsToUS = ["/us", "/us/", "/ca", "/ca/", "/eu", "/eu/"];
const redirectRegionsToCA = ["/", "/us", "/us/", "/eu", "/eu/"];
const redirectRegionsToEU = ["/", "/us", "/us/", "/ca", "/ca/"];

const AppRoutes = () => {
  const { region } = useSelector(selectLocalizationData);
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;
  const location = useLocation();

  if (regional === "US" && redirectRegionsToUS.includes(location.pathname)) {
    if (redirectRegionsToUS.some((region) => region === location.pathname)) {
      return <Redirect to="/" />;
    }
  }
  if (regional === "CA" && redirectRegionsToCA.includes(location.pathname)) {
    if (redirectRegionsToCA.some((region) => region === location.pathname)) {
      return <Redirect to="/ca" />;
    }
  }
  if (
    regional !== "CA" &&
    regional !== "US" &&
    redirectRegionsToEU.includes(location.pathname)
  ) {
    if (redirectRegionsToEU.some((region) => region === location.pathname)) {
      return <Redirect to="/eu" />;
    }
  }

  return <>{renderRoutes(Routes)}</>;
};
loadableReady(() => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <HelmetProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>,
    document.getElementById("root")
  );
});
