import React from 'react';
import smilingFaceWithSmilingEyes from 'assets/images/smiling-face-with-smiling-eyes.png';
import wavingHand from 'assets/images/waving-hand.png';
import nerdFace from 'assets/images/nerd-face.png';
import loadable from '@loadable/component';

const LazyImg = loadable(() => import('../components/common/LazyImg'));

const appChangeLogStatic = [
  {
    version: '1.17.1',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>What's New:</strong>
        </p>
        <ul>
          <li>
            We squashed some additional bugs and made tweaks behind the scenes
            to improve overall stability and performance.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.17',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>What's New:</strong>
        </p>
        <ul>
          <li>
            Your favorite air purifier is now more accessible (and fun!) than
            ever and includes Mila Air Mini support. &nbsp;
            <a
              href='https://airmini.milacares.com'
              target='_blank'
              rel='noreferrer'
            >
              Preorder yours today!
            </a>
          </li>
          <li>
            We squashed some additional bugs and made tweaks behind the scenes
            to improve overall stability and performance.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.16.1',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>What's New:</strong>
        </p>
        <ul>
          <li>
            We're excited to introduce a new Home Health Report to help you
            understand your home air quality better.
          </li>
          <li>
            Keychain AutoFill Support: We’ve improved the login experience to
            allow for an easier time with your login credentials. The first time
            you login, the app will ask if you want to save your credentials to
            the keychain - allowing you to AutoFill them without having to type
            out your username or password.
          </li>
          <li>We've squashed bugs to improve the user experience.</li>
        </ul>
      </>
    ),
  },
  {
    version: '1.15.1',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>Improvements:</strong>
        </p>
        <p>
          <strong>Interface Update:</strong>
          {' '}
          Your user interface just got a
          minor upgrade with various fixes and adjustments for a more intuitive
          experience.
        </p>
        <p>
          <strong>Bug Fixes:</strong>
        </p>
        <ul>
          <li>
            We resolved an issue where Total Volatile Organic Compounds (TVOC)
            labels may be incorrectly displayed at various thresholds and where
            it now tracks better with your overall air quality levels.
          </li>

          <li>
            We squashed some additional bugs and made tweaks behind the scenes
            to improve overall stability and performance.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.14',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>What’s New:</strong>
        </p>
        <ul>
          <li>
            <p>
              <strong>See (and breathe) into the future:</strong>
              {' '}
              With Air
              Quality Forecast Alerts, stay ahead of the incoming air conditions
              thanks to 24-hour forecasts for ozone, smoke, dust and AQI
              increases. If an air event is headed to your neck of the woods,
              you’ll automatically receive a push notification and an update
              from your Milabot.
            </p>
          </li>
          <li>
            <p>
              <strong>Travel with the air:</strong>
              {' '}
              With the interactive map,
              explore the 24-hour forecasts by seeing how these air quality
              events are moving through your neck of the woods.
            </p>
          </li>
          <li>
            <p>
              <strong>Reimagine how your house breathes:</strong>
              {' '}
              iOS
              enthusiasts can now unlock beta access to Apple HomeKit by pairing
              the smartest air purifier with all the smart devices in your home.
            </p>
          </li>
        </ul>
        <p>
          <strong>Improvements</strong>
        </p>
        <ul>
          <li>
            We've made some underlying changes to our push notification system,
            so your notifications are more timely and more relevant.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.13',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          Mila’s 1.13 release coming in hot 🔥 with some key updates on both iOS
          and Android.
        </p>
        <p>
          For our air breathers on iOS, our latest release brings the Mila app
          right to your iPhone Home Screen and Today view, so you can even more
          obsessively check your real-time air quality. Choose from a selection
          of adorable widgets to get an instant view of your most need-to-know
          air quality trends. New to widgets? Get a quick how-to
          {' '}
          <a href='/lp/update-113' target='_blank'>
            here
          </a>
          .
        </p>
        <p>
          <strong>THE LITTLE WIDGETS</strong>
        </p>
        <p>Small, digestible bites of data in one quick glance.</p>
        <p>
          <strong>Indoor Air Quality (12 hours)</strong>
        </p>
        <p>
          Want a lil’ peek at your indoor air quality? Opt for this small
          widget. Ooo what’s that spike around 2pm today? Get to the bottom of
          it 🕵️‍♂️ or just admire that beautiful data viz.
        </p>
        <p>
          <strong>Outdoor Air Quality (12 hours)</strong>
        </p>
        <p>
          This little guy gives you a quick snapshot of your outdoor air quality
          over the last 12 hours. Was it 👍 or 😵 while you were out and about?
          You might get some hints on the safest times to head outdoors this
          spring.
        </p>
        <p>
          <strong>Weekly Outdoor AQI Trends</strong>
        </p>
        <p>
          Find out through seven strips of pretty colors how your outdoor air
          quality looked over the last 7 days. Green means good, yellow means
          meh, and red means yiiikes.
        </p>
        <p>
          <strong>Allergen Levels</strong>
        </p>
        <p>
          Before you lace up your kicks and head outside, quickly see what
          current allergen levels are around you. This might be a day to enjoy
          the great indoors if your biggest pollen nemesis is blazing. 🤧
        </p>
        <p>
          <strong>THE MEDIUM WIDGETS</strong>
        </p>
        <p>For a mid-sized, mildly neurotic view.</p>
        <p>
          <strong>Indoor Air Quality (24 hours)</strong>
        </p>
        <p>
          Prefer a bigger, full-day glance at how things have been looking
          inside? Boom. Here it is. This one will come in handy when you
          discover all the bacon is gone and you need some hard evidence of who
          the cooking culprit was. 🤨
        </p>
        <p>
          <strong>Outdoor Air Quality (24 hours)</strong>
        </p>
        <p>
          If more data doesn’t scare you, grab this bigger widget to dive into a
          wider view of your outdoor air quality. Now you can geek out about how
          your local air quality shifts every hour on the hour, and tell all
          your friends. 🤓
        </p>
        <p>
          <strong>THE BIG-BOY WIDGETS</strong>
        </p>
        <p>For the full-nerd look at what’s going on.</p>
        <p>
          <strong>Comprehensive Indoor View</strong>
        </p>
        <p>
          This superwidget gives you comprehensive stats on indoor vs outdoor
          air quality, and what your humidity, VOC, and CO sensors are picking
          up. If you were hoping for a good hair day and the humidity’s at 74%,
          well…don’t even bother showering.
        </p>
        <p>
          <strong>Comprehensive Outdoor View</strong>
        </p>
        <p>
          This outdoor widget on steroids gives you a megaview of outdoor
          highlights in one glance—including outdoor air quality and allergen
          levels, plus weekly outdoor air quality trends. Impress your crush
          with your knowledge of how weekday air quality compares to weekends in
          your area. 😍
        </p>
        <p>
          And for those hardcore Miliebers out there with Milas across multiple
          locations (like a vacation home or office), you’ll also have the
          ability to set a location for each widget. Which opens up even more
          fun data comparisons. Enjoy that rabbit hole and if you have any
          requests for additional widgets, let us know. 🙌
        </p>
        <p>
          <strong>And for our air breathers with Android … </strong>
        </p>
        <p>
          Say hello to a brand new onboarding and set up flow. 🙌 Mila will now
          automatically connect during the pairing process to make it easier
          than ever to get started.
          {' '}
        </p>
        <p>
          <strong>High vibin’ with a new mode</strong>
        </p>
        <p>
          And for all our breathers, we give you: Chronic Mode. Activate this
          mode and Mila will go into overdrive to get rid of even the stickiest
          of the icky in the air.
        </p>
        <p>
          Chronic Mode is a touch more hidden than others, for obvious reasons.
          To activate it, add or switch your room type to Music Studio in the
          Mila app. Then kick back and enjoy while Mila clears the air.
        </p>
      </>
    ),
  },
  {
    version: '1.11',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          This release expands on the multi-location support we recently
          introduced in 1.10, and now gives your Milas the ability to team-up to
          tackle larger rooms and offices.
        </p>
        <p>
          <strong>New Features</strong>
        </p>
        <ul>
          <li>
            If you’ve been running multiple Milas in a ginormous living room,
            open concept living space, office, lobby, or classroom, then this
            release is for you. You can now add multiple Milas to the same room
            and control them at once. And just like with your Milas, you can
            give a custom name for each room.
          </li>
          <li>
            Your modes and schedules will automatically sync across all Milas in
            a room, and get an aggregate report by room on your overall indoor
            air quality.
          </li>
          <li>
            You can now move your Mila from one room (or location) to another
            directly within the app.
          </li>
        </ul>
        <p>
          <strong>Improvements & Bug Fixes</strong>
        </p>
        <ul>
          <li>
            Sensor charts (AQI, CO2 and humidity) get an upgrade, and you’ll
            notice some visual refinements with new and improved charts.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.10',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          Say hello to a more dynamic Mila with multi-location and multi-user
          support for you and everyone in your clean air bubble. This latest
          release unlocks the ability to set up Milas across multiple locations,
          and share access with other members of your household or extended Mila
          squad.
          {' '}
        </p>
        <p>
          <strong>New Features</strong>
        </p>
        <ul>
          <li>
            Set a location for each of your Milas, whether you use them in your
            home, vacation home, college dorm, or office.
          </li>
          <li>
            Give guest or admin access on a location-by-location basis with
            other members of your household or work crew.
          </li>
          <li>
            Define location-specific settings including: personalized names,
            localized outdoor AQI readings, distance unit (km vs. miles), and
            temperature unit (Celsius vs. Fahrenheit).
          </li>
        </ul>
        <p>
          <strong>Improvements & Bug Fixes</strong>
        </p>
        <ul>
          <li>
            General optimizations to keep your favorite clean air app running
            smoothly.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.9',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          Our original charts looked tasty but lacked the ability for you to
          dive deep. We wanted users to be able to see longer-term seasonal
          trends in their air quality as well as be precise enough to spot how
          their home air quality changes throughout the day from things like
          cooking, vacuuming, opening the front door, taking a shower, jumping
          on the bed, etc. Improvements include:
          {' '}
        </p>
        <p>
          <strong>New Features</strong>
        </p>
        <ul>
          <li>
            New line charts with dynamic ranges and timestamps. You will now be
            able to see the details of events that happen in your home and also
            pinpoint when.
          </li>
          <li>
            Seamless navigation back in time to see what your readings were last
            month (or even last year!)
          </li>
          <li>
            Outdoor AQI readings are also getting an upgrade. You will now see
            weekly, quarterly and yearly timescales to see the seasonal trends
            in your local air quality.
          </li>
        </ul>
        <p>
          <strong>Improvements & Bug Fixes</strong>
        </p>
        <ul>
          <li>Improved speed.</li>
          <li>
            Improved login and password reset experience, including a shiny new
            login screen.
          </li>
          <li>Fixed some nasty bugs.</li>
          <li>
            App size optimizations to minimize Mila’s footprint on your mobile
            device.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.8',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          The 1.8 app release now tracks all wildfire reports across the US, and
          will notify you when you're in a wildfire smoke zone. It'll even warn
          you if wildfire smoke is in the forecast within the next 24 hours.
          {' '}
        </p>
        <p>
          <strong>New Features</strong>
        </p>
        <ul>
          <li>
            <strong>Active Wildfire Alert:</strong>
            {' '}
            Know when you're within a
            150 mile radius of an active wildfire. (For US users only: we're
            looking to see how we can incorporate this for other countries, but
            haven't found the right solution yet. We don't currently have a
            timeline for this.)
          </li>
          <li>
            <strong>Wildfire Smoke Alert:</strong>
            {' '}
            The smoke from wildfires can
            travel thousands of miles and affect the air quality in other
            states. Therefore, Mila not only tracks active wildfire locations,
            but will also provide realtime smoke predictions if you're likely to
            be impacted by wildfire smoke within the next 24 hours.
          </li>
          <li>
            <strong>Snooze Mila Notifications:</strong>
            {' '}
            You'll now have ability
            to "snooze" your notifications for 48hrs. This is particularly
            useful for Pollen and Smoke Alerts, which can last for days at a
            time.
          </li>
        </ul>
        <p>
          <strong>Improvements & Bug Fixes</strong>
        </p>
        <ul>
          <li>
            <strong>Alerts:</strong>
            {' '}
            We improved the way that alerts are
            prioritized, so that you'll always have the most important alerts at
            hand.
          </li>
          <li>
            <strong>iOS 15 bug fixes/optimizations:</strong>
          </li>
          <li>Fixed the bug with minimizing the Room screen</li>
          <li>
            Fixed the bug associated with selecting from different outdoor AQI
            stations
          </li>
          <li>
            Fixed a bug when opening/closing the Neighborhood (Outdoor) details
            card
          </li>
          <li>
            <strong>Android improvements and bug fixes:</strong>
          </li>
          <li>
            User Experience - Improved how haptic feedback is handled within the
            app. Added a simple way to copy Technical Info (MAC
            address/firmware) if you ever need support
          </li>
          <li>Added support for Android 12</li>
          <li>Optimized onboarding screens for smaller screens</li>
          <li>Minor UI improvements</li>
        </ul>
      </>
    ),
  },
  {
    version: '1.7.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            <strong>Pollen Tracking:</strong>
            {' '}
            Track your local pollen readings
            and trends in your Milabot (your app's main screen) and on your
            Neighborhood screen. Mila pulls true pollen count from the American
            Academy of Allergy, Asthma & Immunology’s National Allergy Bureau
            (NAB) for the United States and the European Aeroallergen Network
            (EAN) in Europe (covers France, Germany, and Switzerland).
            <br />
            <br />
            In addition to pollen tracking, the 1.7 release adds two of our most
            popular customer requests: an Energy Save Mode and a Child Lock for
            tiny fingers and paws.
            <br />
            <br />
          </li>
          <li>
            <strong>Energy Save Mode:</strong>
            {' '}
            When Mila detects prolonged
            periods of great air quality, it'll go into hibernation, turning the
            fan and display off. However, Mila will continue to monitor your air
            and spin back up when needed.
          </li>
          <li>
            <strong>Child Lock:</strong>
            {' '}
            You can now disable your Mila's
            on-screen controls to keep little fingers and paws from switching
            your settings. You'll still have full control through your Mila app.
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.6.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <p>
          <strong>Meet the Milabot.</strong>
          {' '}
          <LazyImg src={wavingHand} alt='waving-hand' />
          {' '}
          A real-time feed
          updating you on what your Mila(s) are up to, and what they're sensing.
          And as you might have guessed, it's big on gifs and emojis.
          <LazyImg src={smilingFaceWithSmilingEyes} alt='smiling-face' />
        </p>
        <p>Here are some things the new Milabot can do for you: </p>
        <ul>
          <li>
            <strong>Realtime Air Quality Alerts</strong>
            {' '}
            - Mila will send you a
            push notification if it detects any issues with your indoor or
            outdoor air quality, including elevated indoor AQI, Outdoor AQI,
            VOC, CO2, CO, and humidity levels.
          </li>
          <li>
            <strong>Daily, Weekly, Monthly Logs</strong>
            {' '}
            - Mila not only tells
            you what's going on in your home right now, it'll show you trends
            over the past day, week, or month. Get to know what affects the air
            quality and overall health of your home.
          </li>
          <li>
            <strong>Milabot Insights (Beta)</strong>
            {' '}
            - Mila not only tells you
            what's going on in your home right now, it'll show you trends over
            the past day, week, or month. Get to know what affects the air
            quality and overall health of your home.
            <ul>
              <li>
                <strong>Sleep Analysis</strong>
                {' '}
                - Receive a daily Sleep Analysis
                to know what your bedroom air was like while you slept.
              </li>
              <li>
                <strong>Mold Alerts </strong>
                {' '}
                - Get an alert not just when
                humidity is high, but also when the conditions are right for
                mold to start sprouting, including how long it would take for
                mold growth to occur if conditions don't change.
              </li>
              <li>
                <strong>Safeguard Alerts</strong>
                {' '}
                - The Milabot can predict when
                your windows are open and let you know to close them if the
                outdoor AQI is rising and it can't keep up. You may have noticed
                it on your Mila units already — and you'll now get those same
                alerts on your phone.
              </li>
              <li>
                And more to come ... Mila's always getting smarter.
                <LazyImg src={nerdFace} alt='nerd-face' />
              </li>
            </ul>
          </li>
          <li>
            <strong>Smart Status Mode</strong>
            {' '}
            - Have you ever wondered what
            your Milas are thinking about all day long? The Milabot will let you
            know what each Mila is up to and what mode it's in — and gives you
            the ability to adjust it right from your phone. For example, if your
            Mila didn't pick up that you're in the Living Room and goes into
            Housekeeper mode, you can easily switch it off straight from you
            app.
          </li>
          <li>
            <strong>Thoughtful Controls</strong>
            {' '}
            - And of course, the Milabot is
            thoughtful. Nobody likes getting spammed with app notifications so
            you'll always have the ability to turn on/off various notification
            types via the shiny new "Alerts" panel in your Settings.
          </li>
        </ul>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>Typos and formatting</li>
        </ul>
      </>
    ),
  },
  {
    version: '1.5.0',
    time: '2020-12-29',
    prettyTime: 'March 02, 2021',
    description: (
      <>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            <strong>Set Automagic Modes for each Mila independently.</strong>
            {' '}
            <p>
              Personalize your Milas however you see fit: be a Bubble Boy in
              your home office while you enjoy Quiet Mode in the living room.
            </p>
          </li>
          <li>
            <strong>Give each Mila its own Bedtime Schedule.</strong>
            {' '}
            <p>
              A big request was to be able to set a separate bedtime schedules
              in the kid's room or baby's nursery. In this build we give you the
              ability to customize the bedtime schedule for each Mila
              independently.
            </p>
          </li>
          <li>
            <strong>
              And...you can now personalize Whitenoise Mode, Sleep Mode, and
              Turndown Service.
            </strong>
            {' '}
            <p>
              So many of you have sent us notes telling us how much you love
              Mila's special bedtime features, but the #1 request has been to
              have even more personalization. Some of you suffer from nighttime
              allergies and wanted Mila running at a higher ACH during Turndown
              Service and Sleep Mode. While others are light sleepers and wanted
              Mila whisper quiet at night. And so many of you love Whitenoise
              Mode but some of you wanted it to sound like a Beachfront Bliss
              while others wanted it to sound like a Calm Creek.
            </p>
          </li>
        </ul>
      </>
    ),
  },
  {
    version: '1.4.0',
    time: '2020-12-29',
    prettyTime: 'Dec 29, 2020',
    description: (
      <>
        <p>
          <strong>New Features and Improvements</strong>
        </p>
        <ul>
          <li>
            <strong>Self-initiated sensor calibration</strong>
            {' '}
            — run through a
            sensor calibration sequence anytime.
          </li>
          <li>
            <strong>Access technical info within the app</strong>
            {' '}
            — access info
            such as your Mila's current firmware version and MAC address
            straight from the app.
          </li>
          <li>
            <strong>Custom naming</strong>
            {' '}
            — give your rooms custom names (new!)
            or change room types without having to re-boot your Mila.
          </li>
        </ul>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            Fixed truncated AQI value in oAQI station selection screen when AQI
            levels go above 100.
          </li>
        </ul>
      </>
    ),
  },
];

export default appChangeLogStatic;
