import React, { useEffect, useState, useRef } from "react";
import { renderRoutes } from "react-router-config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import loadable from "@loadable/component";
import "assets/css/style.scss";

import {
  fetchBanners,
  selectBannersStatus,
  setBannersIdle,
} from "./features/banners/bannersSlice";
import { selectFallbackLoading } from "./features/fallback/fallbackSlice";

import { STATUS, PAGES_WITHOUT_NAV } from "../shared/enums";

const HelmetWrapper = loadable(() => import("./components/root/HelmetWrapper"));
const ErrorBoundary = loadable(() => import("./components/root/ErrorBoundary"));
const Footer = loadable(() => import("./components/root/Footer"));
const Header = loadable(() => import("./components/root/Header"));
const HeaderRefresh = loadable(() => import("./components/root/HeaderRefresh"));
const FooterRefresh = loadable(() => import("./components/root/FooterRefresh"));
const Icons = loadable(() => import("./components/root/Icons"));
const ScrollToTop = loadable(() => import("./components/root/ScrollToTop"));
const TopBar = loadable(() => import("./components/root/TopBar"));

/**
 * @param route
 * @param pathname
 * @returns {JSX.Element}
 * @constructor
 */

const App = ({ route, location: { pathname } }) => {
  const dispatch = useDispatch();
  const status = useSelector(selectBannersStatus);
  const { loading } = useSelector(selectFallbackLoading);
  const [isNavActive, setIsNavActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(false);

  const topBarRef = useRef(null);
  const headerRef = useRef(null);
  const mainRef = useRef(null);

  const { PREFETCHED } = STATUS;

  const isMenuActive = isNavActive ? "nav__visible" : "";
  const isScrollPosition = scrollPosition ? "scroll-up" : "scroll-down";

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    status !== PREFETCHED
      ? dispatch(fetchBanners())
      : dispatch(setBannersIdle());

    return undefined;
  }, [pathname]);

  useEffect(() => {
    let lastScrollTop = 0;

    function scrollDirection() {
      const pageOffsetY =
        window.pageYOffset || document.documentElement.scrollTop;
      if (pageOffsetY > lastScrollTop) {
        setScrollPosition(true);
      } else if (pageOffsetY < lastScrollTop) {
        setScrollPosition(false);
      }
      lastScrollTop = pageOffsetY <= 0 ? 0 : pageOffsetY;
    }
    window.addEventListener("scroll", scrollDirection);

    return () => {
      window.removeEventListener("scroll", scrollDirection);
    };
  }, []);

  const location = useLocation();
  const excludePaths = ["/", "/us", "/ca"];

  return (
    <div
      className={`${
        loading ? "components-loading " : ""
      } ${isMenuActive} ${isScrollPosition}`}
    >
      <ScrollToTop />
      <HelmetWrapper pathname={pathname} />
      <ErrorBoundary>
        {!PAGES_WITHOUT_NAV.includes(pathname) && (
          <TopBar
            topBarRef={topBarRef}
            pathname={pathname}
            className={
              excludePaths.some((path) => location.pathname.startsWith(path))
                ? " top-bar--light"
                : ""
            }
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        {!excludePaths.some((path) => location.pathname.startsWith(path)) ? (
          <Header
            pathname={pathname}
            isNavActive={isNavActive}
            setIsNavActive={setIsNavActive}
            mainRef={mainRef}
            topBarRef={topBarRef}
            headerRef={headerRef}
          />
        ) : (
          <HeaderRefresh
            pathname={pathname}
            topBarRef={topBarRef}
            headerRef={headerRef}
            className={`${
              excludePaths.some((path) => location.pathname.startsWith(path))
                ? " header--alt"
                : ""
            }`}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        <main
          className={`main ${
            excludePaths.some((path) => location.pathname.startsWith(path))
              ? " main--alt"
              : ""
          }`}
          ref={mainRef}
        >
          {renderRoutes(route.routes, { topBarRef, headerRef })}
        </main>
      </ErrorBoundary>
      <ErrorBoundary>
        <ErrorBoundary>
          {!excludePaths.some((path) => location.pathname.startsWith(path)) ? (
            !PAGES_WITHOUT_NAV.includes(pathname) && (
              <Footer pathname={pathname} />
            )
          ) : (
            <FooterRefresh pathname={pathname} />
          )}
        </ErrorBoundary>
      </ErrorBoundary>
      <Icons />
    </div>
  );
};

const loadData = (store) => {
  const actions = [fetchBanners()];
  return Promise.all(actions.map((action) => store.dispatch(action)));
};

export default {
  component: App,
  loadData,
};
