/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const airAppChangeLogStatic = [
  {
    version: '2.2.0',
    time: null,
    prettyTime: null,
    description: (
      <>
        <p>
          <strong>Bug Fixes</strong>
        </p>
        <ul>
          <li>
            Fixed a time synchronization issue that could cause Mila’s schedules
            to fall out of sync if it couldn’t connect to the Internet
            immediately after restarting.
          </li>
          <li>
            Fixed random crashes that some users experienced due to HomeKit
            interactions on certain networks.
          </li>
        </ul>
      </>
    ),
  },
];

export default airAppChangeLogStatic;
